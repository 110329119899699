import { marked } from 'marked'

export function useMarkedContent() {
  const markedContent = (text: string, target = '_self', multiBreak = false) => {
    // disable wrapping with <p>
    marked.Renderer.prototype.paragraph = function (text) {
      return text + '\n'
    }
    marked.Renderer.prototype.link = (href, _title, text) => {
      return `<a target="${target}" href="${href}" rel="noopener noreferrer">${text}</a>`
    }
    // allow consecutive \n
    if (multiBreak) {
      return marked(text.replaceAll(/\n/g, '<br />'))
    }
    return marked(text)
  }

  return { markedContent }
}
