import { useIdle } from '@vueuse/core'
import UsersApi from '@/features/account/api/users.api'
import { CORE_SET_USER } from '@/common/stores/core/mutation.types'
import store from '@/common/stores/store'
import { logError } from '@/common/utils/errors/error-handlers'

const { idle } = useIdle()
const timeoutInMins = 5
const timeoutInMsecs = timeoutInMins * 60 * 1000

export default function pollUserData() {
  setInterval(async () => {
    // If user is idle (not interacting with the page), skip the poll
    if (idle.value) {
      return
    }

    try {
      const user = await UsersApi.getMe()
      store.commit(CORE_SET_USER, user)
    } catch (error) {
      logError(error)
      // deliberately do nothing besides logging the error
    }
  }, timeoutInMsecs)
}
