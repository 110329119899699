import axios from '@/common/utils/axios'
import type { AddressBasePayload, ReturnAddress } from '@/features/addresses/types/addresses.types'

export default {
  async create(newAddress: AddressBasePayload) {
    const { data } = await axios.post('/xhr/returnaddress', newAddress)
    return data
  },

  async update(id: ReturnAddress['id'], updatedData: Partial<ReturnAddress>) {
    const { data } = await axios.put(`/xhr/returnaddress/${id}`, updatedData)
    return data
  },

  delete(id: ReturnAddress['id']) {
    return axios.delete(`/xhr/returnaddress/${id}`)
  },

  async findAll() {
    const { data } = await axios.get('/xhr/returnaddress')
    return data
  },

  async find(id: ReturnAddress['id']) {
    const { data } = await axios.get(`/xhr/returnaddress/${id}`)
    return data
  },

  async setActive(id: ReturnAddress['id']) {
    const { data } = await axios.post(`/xhr/returnaddress/${id}/activate`, {})
    return data
  },
}
