import { isAxiosError } from 'axios'

import store from '@/common/stores/store'
import {
  CORE_FETCH_COUNTRIES,
  CORE_FETCH_BASIC_PLANS_INFO,
  CORE_FETCH_DEFINITIONS,
  CORE_FETCH_USER,
} from '@/common/stores/core/action.types'
import { INTEGRATIONS_FETCH_ALL } from '@/features/integrations/stores/action.types'
import { useOnboardingWizardStore } from '@/features/onboarding-wizard/stores/index'
import { SHIPPING_FETCH_DEFAULT_SETTINGS } from '@/features/shipping-defaults/stores/action.types'

import { logError } from '@/common/utils/errors/error-handlers'

async function fetchOnboardingWizardSteps(): Promise<void> {
  const onboardingWizardStore = useOnboardingWizardStore()
  const showOnboardingWizard = onboardingWizardStore.isWizardShown

  if (showOnboardingWizard) {
    await onboardingWizardStore.fetchSteps()
  }
}

export class BootupError extends Error { }

export default {
  async fetchInitialStoreData(): Promise<void> {
    const onboardingWizardStore = useOnboardingWizardStore()

    try {
      await Promise.all([
        // These requests must succeed for the application to run. If they fail for any reason,
        // redirect to the login page.
        store.dispatch(CORE_FETCH_USER),
        store.dispatch(CORE_FETCH_DEFINITIONS),
        // This request has its own internal error handling and will not trigger the catch clause on error.
        onboardingWizardStore.fetchStatus(),
      ])
    } catch (error: unknown) {
      logError(error)
      // Throw the error up to the catch clause in prepare-app.js for proper handling.
      if (isAxiosError(error) && error.response?.status === 403) {
        throw error
      } else {
        // If it's not a 403, this means the application cannot load and we need to inform the user,
        // so we must handle it differently than a 403.
        throw new BootupError()
      }
    }

    // If these requests fail, we should allow the UI to load but report unusual errors to Sentry.
    try {
      await Promise.all([
        store.dispatch(CORE_FETCH_BASIC_PLANS_INFO),
        store.dispatch(SHIPPING_FETCH_DEFAULT_SETTINGS),
        store.dispatch(CORE_FETCH_COUNTRIES),
        store.dispatch(INTEGRATIONS_FETCH_ALL),
        fetchOnboardingWizardSteps(),
      ])
    } catch (error: unknown) {
      // If the user isn't authenticated, redirect to the login page.
      if (isAxiosError(error) && error.response?.status === 403) {
        // Throw the error up to the catch clause in prepare-app.js for proper handling.
        throw error
      }

      logError(error)
    }
  },
}
