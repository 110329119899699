// This is a copy from the constants in the notify backend in
// python/sendcloud/notify/constants.py
import type {
  TrackingMessagePostPurchaseType,
  TrackingMessagesDirection,
} from '@/features/tracking-messages/types/tracking-messages.types'
import type { TrackingMessagesSettingsPerBrand } from './types/tracking-messages-data-parsers'

export const TRACKING_MESSAGE_DEFAULTS = {
  PREVIEW_ENABLED: ['EMAIL'],
}

export const WHATSAPP_DIRECTION_MAP: TrackingMessagesDirection = {
  o: 'shipping',
  i: 'returns',
  f: 'follow_up',
}

export const POST_PURCHASE_TYPES: Record<'POST_PURCHASE_EMAIL' | 'TRUSTPILOT', TrackingMessagePostPurchaseType> = {
  POST_PURCHASE_EMAIL: 'postpurchase',
  TRUSTPILOT: 'trustpilot',
}

export const TRACKING_WHATSAPP_PERSONAL_ACCOUNT_FEATURE = 'tracking_messages_whatsapp_personal_account'

export const WHATSAPP_PROVIDER = {
  SENDCLOUD_WABA: 'sendcloud_cm',
  PERSONAL_WABA: 'personal_360d',
} as const

export const WHATSAPP_TEMPLATE_STATUSES = {
  DRAFT: 'draft',
  APPROVED: 'approved',
  IN_APPEAL: 'in_appeal',
  SUBMITTED: 'submitted',
  PENDING: 'pending',
  REJECTED: 'rejected',
  PENDING_DELETION: 'pending_deletion',
  DELETED: 'deleted',
  DISABLED: 'disabled',
  PAUSED: 'paused',
  LIMIT_EXCEEDED: 'limit_exceeded',
} as const

export const TEMPLATE_STATUSES_CONSIDERED_PENDING = [
  WHATSAPP_TEMPLATE_STATUSES.PENDING,
  WHATSAPP_TEMPLATE_STATUSES.DRAFT,
  WHATSAPP_TEMPLATE_STATUSES.IN_APPEAL,
  WHATSAPP_TEMPLATE_STATUSES.SUBMITTED,
]

export const WHATSAPP_TEMPLATE_REJECTED_REASONS = {
  ABUSIVE_CONTENT: 'ABUSIVE_CONTENT',
  INVALID_FORMAT: 'INVALID_FORMAT',
  NONE: 'NONE',
  PROMOTIONAL: 'PROMOTIONAL',
  TAG_CONTENT_MISMATCH: 'TAG_CONTENT_MISMATCH',
  SCAM: 'SCAM',
} as const

// THERE WILL BE JUST ONE OF THE FIRST 4 KEYS
// THERE WILL BE JUST ONE OF THE LAST 2 KEYS
// WE COULD HAVE A UNION OF THE FIRST 4 KEYS AND THE LAST 2 KEYS
export const WHATSAPP_ALERTS_KEYS = {
  ACCOUNT_REGISTRATION_FAILED: 'account_registration_failed',
  ACCOUNT_UNREGISTERED: 'account_unregistered',
  WABA_NOT_VERIFIED: 'waba_not_verified',
  ACCOUNT_REGISTRATION_PENDING: 'account_registration_pending',
  DISPLAY_NAME_PENDING: 'display_name_pending',
  DISPLAY_NAME_REJECTED: 'display_name_rejected',
  NUMBER_ALREADY_CONNECTED: 'number_already_connected_to_wa',
}

export const WHATSAPP_ACCOUNT_STATUSES = {
  CONNECTED: 'connected',
  NOT_CONNECTED: 'not_connected',
  FAILED: 'failed',
} as const

export const META_VERIFY_WHATSAPP_ACCOUNT_GUIDELINES_URL =
  'https://www.facebook.com/business/help/2058515294227817?id=180505742745347'

export const META_DISPLAY_NAME_GUIDELINES_URL = 'https://www.facebook.com/business/help/757569725593362'

export const TEMPLATE_STATUS_BADGE_MAP: Record<
  string,
  {
    badgeStatus: string
    badgeTextKeyPath: string
  }
> = {
  [WHATSAPP_TEMPLATE_STATUSES.DRAFT]: {
    badgeStatus: 'pending',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.pending',
  },
  [WHATSAPP_TEMPLATE_STATUSES.APPROVED]: {
    badgeStatus: 'success',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.approved',
  },
  [WHATSAPP_TEMPLATE_STATUSES.IN_APPEAL]: {
    badgeStatus: 'pending',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.pending',
  },
  [WHATSAPP_TEMPLATE_STATUSES.SUBMITTED]: {
    badgeStatus: 'pending',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.pending',
  },
  [WHATSAPP_TEMPLATE_STATUSES.PENDING]: {
    badgeStatus: 'pending',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.pending',
  },
  [WHATSAPP_TEMPLATE_STATUSES.REJECTED]: {
    badgeStatus: 'error',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.rejected',
  },
  [WHATSAPP_TEMPLATE_STATUSES.PENDING_DELETION]: {
    badgeStatus: 'error',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.pending_deletion',
  },
  [WHATSAPP_TEMPLATE_STATUSES.DELETED]: {
    badgeStatus: 'error',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.deleted',
  },
  [WHATSAPP_TEMPLATE_STATUSES.DISABLED]: {
    badgeStatus: 'error',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.disabled',
  },
  [WHATSAPP_TEMPLATE_STATUSES.PAUSED]: {
    badgeStatus: 'error',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.paused',
  },
  [WHATSAPP_TEMPLATE_STATUSES.LIMIT_EXCEEDED]: {
    badgeStatus: 'error',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.limit_exceeded',
  },
  action_required: {
    badgeStatus: 'warning',
    badgeTextKeyPath: 'features.tracking_messages.whatsapp.template_status.action_required',
  },
}

export const EDIT_TEMPLATE_ACTION_TYPES = {
  TRACKING_LINK: 'trackingLink',
  WEBSITE: 'website',
  PHONE_NUMBER: 'phoneNumber',
  NO_BUTTON: 'noButton',
}

export const EDIT_TEMPLATE_ACTION_TYPE_SELECT_OPTIONS = [
  {
    value: EDIT_TEMPLATE_ACTION_TYPES.TRACKING_LINK,
    labelKey: 'features.tracking_messages.whatsapp.edit_template_modal.call_to_action.action_type.tracking_link',
  },
  {
    value: EDIT_TEMPLATE_ACTION_TYPES.WEBSITE,
    labelKey: 'features.tracking_messages.whatsapp.edit_template_modal.call_to_action.action_type.website',
  },
  {
    value: EDIT_TEMPLATE_ACTION_TYPES.PHONE_NUMBER,
    labelKey: 'features.tracking_messages.whatsapp.edit_template_modal.call_to_action.action_type.phone_number',
  },
  {
    value: EDIT_TEMPLATE_ACTION_TYPES.NO_BUTTON,
    labelKey: 'features.tracking_messages.whatsapp.edit_template_modal.call_to_action.action_type.no_button',
  },
]

export const CONFIRMATION_MODAL_TYPES = {
  ACTION_REQUIRED: 'action_required',
  RESTORE_TEMPLATE: 'restore_template',
  DISCARD_TEMPLATE: 'discard_template',
  SENT_FOR_APPROVAL: 'sent_for_approval',
  SEND_FOR_APPROVAL: 'send_for_approval',
}

export const PERSONAL_PROVIDER_CONNECTION_ERRORS = {
  connected_to_another_brand: 'connected_to_another_brand',
  deleted: 'deleted',
}

export const SHIPPING_TEMPLATES_KEYS = {
  READY_FOR_SHIPMENT: 'ready_for_shipment',
  OUT_FOR_DELIVERY: 'out_for_delivery',
  DELAYED: 'delayed',
  READY_FOR_PICK_UP: 'ready_for_pick_up',
  DELIVERED: 'delivered',
} as const

export const RETURNS_TEMPLATES_KEYS = {
  LABEL_CREATED: 'label_created',
  HANDED_IN_AT_DROP_OFF: 'handed_in_at_drop_off',
  RETURN_RECEIVED: 'return_received',
} as const

export const FOLLOW_UP_KEYS = {
  POST_PURCHASE: 'post_purchase',
}

export const CUSTOM_TEMPLATES_BUTTON_TYPES = {
  URL: 'URL',
  PHONE_NUMBER: 'PHONE_NUMBER',
  QUICK_REPLY: 'QUICK_REPLY',
} as const

export const CUSTOM_TEMPLATES_COMPONENT_TYPES = {
  BODY: 'BODY',
  FOOTER: 'FOOTER',
  BUTTONS: 'BUTTONS',
} as const

export const POST_PURCHASE_DELAY_VALUES = {
  IMMEDIATE: 'P0DT00H00M00S',
  THIRTY_MINUTES: 'P0DT00H30M00S',
  ONE_HOUR: 'P0DT01H00M00S',
  THREE_HOURS: 'P0DT03H00M00S',
  TWELVE_HOURS: 'P0DT12H00M00S',
  ONE_DAY: 'P1DT00H00M00S',
  THREE_DAYS: 'P3DT00H00M00S',
  ONE_WEEK: 'P7DT00H00M00S',
  TWO_WEEKS: 'P14DT00H00M00S',
  ONE_MONTH: 'P30DT00H00M00S',
} as const

export const CHANNEL_TO_TRACKING_SETTINGS_MAP: Record<string, keyof TrackingMessagesSettingsPerBrand> = {
  EMAIL: 'send_emails',
  SMS: 'send_sms',
  WHATSAPP: 'send_whatsapp',
} as const
