/**
 * This file contains common event tracking functions, that any team can use to track their events.
 * For example, if you need to track any new 'Button Clicked', use trackButtonClicked({...})
 * adding your unique event params to identify it.
 * When creating a new event in the Panel, make sure its reusable, has strictly typed eventParams to avoid typos.
 *
 * This file exists in attempt to organize our events and use common and strictly typed eventNames/eventParams.
 * We want all events to obey similar structure.
 * For example, we want to deprecate all "Some unique button clicked" events
 * in favour of "Button Clicked" event with `button_action: "Your unique button action description"`
 *
 * Best practices:
 *
 * Event names must have uppercase for each word and be in past tense:
 * Wrong: "Button click"
 * Correct: "Button Clicked"
 *
 *
 * Event params must have snake_cased names:
 * Wrong: `buttonAction: "Open some modal"`
 * Correct: `button_action: "Open some modal"`
 */
import Segment from '@/common/utils/tracking/segment'
import type { InsuranceAnalyticsButtonAction } from '@/features/insurance/types/insurance-analytics.types'
import type { ProductsAnalyticsButtonAction } from '@/features/products/types/products-analytics.types'
import type { OpportunityAnalyticsButtonAction } from '@/features/opportunity-center/types/opportunity-center.types'
import type { CarrierAnalyticsButtonAction } from '@/features/carriers/types/carrier.types'
import type { ShippingAnalyticsButtonAction } from '@/features/shipment-forms/types/shipment-form.types'
import type { IncomingOrdersAnalyticsButtonAction } from '@/features/incoming-orders/types/incoming-orders.types'

/**
 * Add your actions here like `... | MyFeatureAnalyticsButtonAction`.
 * This will help you pass correct predefined strings
 */
type ButtonAction =
  InsuranceAnalyticsButtonAction |
  ProductsAnalyticsButtonAction |
  OpportunityAnalyticsButtonAction |
  CarrierAnalyticsButtonAction |
  ShippingAnalyticsButtonAction |
  IncomingOrdersAnalyticsButtonAction

type ButtonEventParams = { button_action: ButtonAction, button_title: string }
type LinkEventParams = { link_action: ButtonAction, link_title: string }

export const trackButtonClicked = (eventParams: ButtonEventParams) =>
  Segment.track('Button Clicked', eventParams)

export const trackButtonShown = (eventParams: ButtonEventParams) =>
  Segment.track('Button Shown', eventParams)

export const trackLinkClicked = (eventParams: LinkEventParams) =>
  Segment.track('Link Clicked', eventParams)
