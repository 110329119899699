<template>
  <slot v-if="isEnabled" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps<{
  flag: string
}>()

const store = useStore()

const isEnabled = computed(() => {
  return store.getters.hasBetaFeature(props.flag)
})
</script>
