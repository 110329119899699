import axios from '@/common/utils/axios'
import type { Brand, BrandBlank } from '@/features/brands/types/brands.types'

export default {
  async find(id: number): Promise<Brand> {
    const response = await axios.get(`/xhr/brand/${id}`)
    return response.data
  },

  async findAll(): Promise<Brand[]> {
    const response = await axios.get('/xhr/brand')
    return response.data
  },

  async create(brand: BrandBlank): Promise<Brand> {
    const response = await axios.post('/xhr/brand', brand)
    return response.data
  },

  async update(id: number, brand: Brand): Promise<Brand> {
    const response = await axios.put(`/xhr/brand/${id}`, brand)
    return response.data
  },

  async delete(id: number): Promise<void> {
    await axios.delete(`/xhr/brand/${id}`)
  },
}
