import type { Commit, Dispatch } from 'vuex'
import InvoiceAddressService from '@/features/addresses/api/invoice-address.api'
import {
  INVOICE_ADDRESS_FETCH_ADDRESSES,
  INVOICE_ADDRESS_UPDATE_ADDRESS,
  INVOICE_ADDRESS_FETCH_ADDRESS,
} from './action.types'
import { INVOICE_ADDRESS_SET_ADDRESS, INVOICE_ADDRESS_SET_ADDRESSES } from './mutation.types'
import type { InvoiceAddress, SenderAddress } from '@/features/addresses/types/addresses.types'

type InvoiceAddressesState = {
  address: SenderAddress
  invoiceAddresses: InvoiceAddress[]
}

const state = {
  address: {},
  invoiceAddresses: [],
}

const getters = {
  address: (state: InvoiceAddressesState) => state.address,

  invoiceAddresses: (state: InvoiceAddressesState) => state.invoiceAddresses,
}

const actions = {
  async [INVOICE_ADDRESS_FETCH_ADDRESSES]({ commit }: { commit: Commit }) {
    const response = await InvoiceAddressService.findAll()
    commit(INVOICE_ADDRESS_SET_ADDRESSES, response.data)
  },

  async [INVOICE_ADDRESS_FETCH_ADDRESS]({ commit }: { commit: Commit }) {
    const response = await InvoiceAddressService.findAll()
    const address = response.data?.length > 0 ? response.data[0] : {}
    commit(INVOICE_ADDRESS_SET_ADDRESS, address)
  },

  async [INVOICE_ADDRESS_UPDATE_ADDRESS]({ dispatch }: { dispatch: Dispatch }, address: InvoiceAddress) {
    await InvoiceAddressService.update(address)
    await dispatch(INVOICE_ADDRESS_FETCH_ADDRESSES)
  },
}

const mutations = {
  [INVOICE_ADDRESS_SET_ADDRESSES](state: InvoiceAddressesState, addresses: InvoiceAddress[]) {
    state.invoiceAddresses = addresses
  },

  [INVOICE_ADDRESS_SET_ADDRESS](state: InvoiceAddressesState, address: SenderAddress) {
    state.address = address
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
