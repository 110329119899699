import Segment from '@/common/utils/tracking/segment'
import type { NavigationItemTrackingData, NavigationCategoryTrackingData } from '@/application/types/navigation-tracking.types'

export default {

  trackNavigationMenuItemClick(params: NavigationItemTrackingData) {
    Segment.track('Navigation Item Selected', {
      item_name: params.name,
      section: params?.parentSection,
    })
  },

  trackNavigationExpandOrCollapsed(isExpanded: boolean) {
    Segment.track('App - Navigation - Expand/Collapse', {
      navigation_expanded: isExpanded,
      navigation_collapsed: !isExpanded,
    })
  },

  trackNavigationCategoryClicked(params: NavigationCategoryTrackingData) {
    Segment.track('App - Navigation - Selected Category ', {
      navigation_category_name: params.name,
      navigation_expanded: params.isExpanded,
    })
  },

  openFeedbackNavigation() {
    Segment.track('App - Navigation feedback')
  },
}
