import type { Commit, Dispatch } from 'vuex'
import {
  SENDER_ADDRESS_CREATE_ADDRESS,
  SENDER_ADDRESS_DELETE_ADDRESS,
  SENDER_ADDRESS_FETCH_ADDRESSES,
  SENDER_ADDRESS_RETRIEVE_LAST_USED_ADDRESS,
  SENDER_ADDRESS_SET_DEFAULT,
  SENDER_ADDRESS_STORE_LAST_USED_ADDRESS,
  SENDER_ADDRESS_UPDATE_ADDRESS,
  SENDER_ADDRESS_FETCH_ADDRESS,
} from '@/features/addresses/stores/sender-addresses/action.types'
import {
  SENDER_ADDRESS_LAST_USED_ADDRESS,
  SENDER_ADDRESS_SET_ADDRESSES,
  SENDER_ADDRESS_SET_ADDRESS,
} from '@/features/addresses/stores/sender-addresses/mutation.types'

import { getVariable, setVariable, LOCAL_STORAGE_KEYS } from '@/common/utils/storage'
import SenderAddressService from '@/features/addresses/api/sender-address.api'
import type { SenderAddress, SenderAddressPayload } from '@/features/addresses/types/addresses.types'

type SenderAddressesState = {
  address: SenderAddress | {}
  senderAddresses: SenderAddress[]
  lastUsedSenderAddressId: SenderAddress['id'] | null
}

const state: SenderAddressesState = {
  address: {},
  senderAddresses: [],
  lastUsedSenderAddressId: null,
}

const getters = {
  senderAddresses: (state: SenderAddressesState) => state.senderAddresses,

  senderCountries: (state: SenderAddressesState) => {
    return state.senderAddresses
      .filter((address, index, array) => {
        return array.findIndex(element => element.country_name === address.country_name) === index
      })
      .map((address) => {
        return {
          name: address.country_name,
          iso_2: address.country_iso_2,
        }
      })
      .sort((countryA, countryB) => countryA.name.localeCompare(countryB.name))
  },

  currentSenderAddress: (state: SenderAddressesState) => {
    if (state.lastUsedSenderAddressId !== null) {
      const lastUsedSenderAddress = state.senderAddresses.find((senderAddress) => {
        return senderAddress.id === state.lastUsedSenderAddressId
      })

      if (lastUsedSenderAddress !== undefined) {
        return lastUsedSenderAddress
      }
    }

    const activeSenderAddress = state.senderAddresses.find(address => address.is_active)
    if (activeSenderAddress !== undefined) {
      return activeSenderAddress
    }

    if (state.senderAddresses[0] !== undefined) {
      return state.senderAddresses[0]
    }

    return null
  },

  findSenderAddressById: (state: SenderAddressesState) => {
    return (senderAddressId: SenderAddress['id']) => {
      return state.senderAddresses.find(senderAddress => senderAddress.id === senderAddressId)
    }
  },

  hasInitialAddress: (state: SenderAddressesState) => {
    return state.senderAddresses.some(senderAddress => senderAddress.initial_address)
  },
}

const actions = {
  async [SENDER_ADDRESS_CREATE_ADDRESS]({ dispatch }: { dispatch: Dispatch }, senderAddress: SenderAddressPayload) {
    await SenderAddressService.create(senderAddress)
    await dispatch(SENDER_ADDRESS_FETCH_ADDRESSES)
  },

  async [SENDER_ADDRESS_DELETE_ADDRESS]({ dispatch, state }: { dispatch: Dispatch, state: SenderAddressesState }, senderAddressId: SenderAddress['id']) {
    await SenderAddressService.destroy(senderAddressId)
    await dispatch(SENDER_ADDRESS_FETCH_ADDRESSES)

    if (senderAddressId === state.lastUsedSenderAddressId) {
      await dispatch(SENDER_ADDRESS_STORE_LAST_USED_ADDRESS, null)
    }
  },

  async [SENDER_ADDRESS_FETCH_ADDRESSES]({ commit }: { commit: Commit }) {
    const response = await SenderAddressService.findAll()
    commit(SENDER_ADDRESS_SET_ADDRESSES, response.data)
  },

  async [SENDER_ADDRESS_UPDATE_ADDRESS]({ dispatch }: { dispatch: Dispatch }, address: SenderAddress) {
    await SenderAddressService.update(address)
    await dispatch(SENDER_ADDRESS_FETCH_ADDRESSES)
  },

  async [SENDER_ADDRESS_SET_DEFAULT]({ dispatch }: { dispatch: Dispatch }, senderAddressId: SenderAddress['id']) {
    await SenderAddressService.setActive(senderAddressId)
    await dispatch(SENDER_ADDRESS_FETCH_ADDRESSES)
  },

  async [SENDER_ADDRESS_RETRIEVE_LAST_USED_ADDRESS]({ commit, getters }: { commit: Commit, getters: any }) {
    const senderAddressId = getVariable(getters.user.id, LOCAL_STORAGE_KEYS.SENDER_ADDRESS_ID)

    if (senderAddressId) {
      commit(SENDER_ADDRESS_LAST_USED_ADDRESS, senderAddressId)
    }
  },

  [SENDER_ADDRESS_STORE_LAST_USED_ADDRESS]({ commit, getters }: { commit: Commit, getters: any }, senderAddressId: SenderAddress['id']) {
    setVariable(getters.user.id, LOCAL_STORAGE_KEYS.SENDER_ADDRESS_ID, senderAddressId)
    commit(SENDER_ADDRESS_LAST_USED_ADDRESS, senderAddressId)
  },

  async [SENDER_ADDRESS_FETCH_ADDRESS]({ commit }: { commit: Commit }, senderAddressId: SenderAddress['id']) {
    const response = await SenderAddressService.find(senderAddressId)
    commit(SENDER_ADDRESS_SET_ADDRESS, response.data)
  },
}

const mutations = {
  [SENDER_ADDRESS_SET_ADDRESSES](state: SenderAddressesState, senderAddresses: SenderAddress[]) {
    state.senderAddresses = senderAddresses
  },

  [SENDER_ADDRESS_LAST_USED_ADDRESS](state: SenderAddressesState, senderAddressId: SenderAddress['id']) {
    state.lastUsedSenderAddressId = senderAddressId
  },

  [SENDER_ADDRESS_SET_ADDRESS](state: SenderAddressesState, address: SenderAddress) {
    state.address = address
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
