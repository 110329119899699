import type { AnyFunction } from '@/common/data-parsers'

export function throttle<T extends AnyFunction, Params extends Parameters<T> = Parameters<T>>(
  func: T,
  delay: number,
  { withTrailingCall = true } = {},
): (...args: Params) => void {
  let canRun = true
  let trailingThis: unknown
  let trailingArgs: Params | null = null

  return function (this: unknown, ...args): void {
    if (!canRun) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      trailingThis = this
      trailingArgs = args
      return
    }

    trailingArgs = null
    func.apply(this, args)
    canRun = false
    const setTimer = () => {
      return setTimeout(() => {
        canRun = true
        if (withTrailingCall && trailingArgs) {
          // if last arguments exist
          // invoke the function with those last arguments
          func.apply(trailingThis, trailingArgs)
          canRun = false
          trailingArgs = null
          setTimer()
        }
      }, delay)
    }
    setTimer()
  }
}
