import axios from '@/common/utils/axios'
import type { SenderAddress, SenderAddressPayload } from '@/features/addresses/types/addresses.types'
import type { SenderAddressParameters } from '@/types/api'

export default {
  create(senderAddress: SenderAddressPayload): Promise<{ data: SenderAddress }> {
    return axios.post('/xhr/senderaddress', senderAddress)
  },

  find(senderAddressId: SenderAddress['id']): Promise<{ data: SenderAddress }> {
    return axios.get(`/xhr/senderaddress/${senderAddressId}`)
  },

  findAll({ countryIso2 }: SenderAddressParameters = {}): Promise<{ data: SenderAddress[] }> {
    return axios.get('/xhr/senderaddress', { params: { country: countryIso2 } })
  },

  destroy(senderAddressId: SenderAddress['id']): Promise<{ data: undefined }> {
    return axios.delete(`/xhr/senderaddress/${senderAddressId}`)
  },

  update(senderAddress: SenderAddress): Promise<{ data: SenderAddress }> {
    return axios.patch(`/xhr/senderaddress/${senderAddress.id}`, senderAddress)
  },

  setActive(senderAddressId: SenderAddress['id']): Promise<{ data: undefined }> {
    return axios.post(`/xhr/senderaddress/${senderAddressId}/activate`)
  },
}
