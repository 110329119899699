import { RouterView, type RouteLocationNormalized, type RouteRecordRaw } from 'vue-router'
import i18n from '@/application/i18n/i18n'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { ReturnListTab } from '@/features/returns/types/enums'
import type { RouteButton } from '@/common/types/route.types'

export const RETURNS_ROUTE = 'returns'
export const RETURNS_LIST_ROUTE = 'returns.list'
export const RETURN_DETAILS_ROUTE = 'returns.detail'
export const RETURN_MANUAL_FORM = 'returns.new'
export const RETURNS_EXPORT = 'returns.export'
export const RETURN_PREFILL_FORM = 'returns.prefilled'

const newReturnFormBtn: RouteButton = {
  title: () => i18n.t('New return'),
  icon: faPlus,
  mode: 'secondary',
  name: RETURN_MANUAL_FORM,
  permissions: [],
}

export default [
  {
    name: RETURNS_ROUTE,
    path: '/returns',
    redirect: () => {
      return { name: RETURNS_LIST_ROUTE, query: { state: ReturnListTab.IN_TRANSIT } }
    },
    component: RouterView,
    meta: {
      pageTitle: () => i18n.t('Returns'),
      permissions: ['manage_returns'],
      buttons: [newReturnFormBtn],
      parent: 'returns-overview',
    },
    children: [
      {
        name: RETURNS_LIST_ROUTE,
        path: '/returns/list',
        component: () => import('@/features/returns/pages/ReturnsListViewPage.vue'),
        props: (route: RouteLocationNormalized) => {
          return {
            currentState: route.query.state,
            searchQuery: route.query.search,
            page: route.query.page,
            pageSize: route.query.page_size,
            parcelStatus: Number.parseInt(route.query.parcel_status as string) || undefined,
            currentParcelStatus: Number.parseInt(route.query.parcel_status as string) || undefined,
            currentReturnStatus: route.query.return_status,
            refundStatus: route.query.refund_type,
            countryStatus: route.query.from_country,
            carrierStatus: route.query.carrier,
            reasonStatus: route.query.reason,
            imagesStatus: route.query.has_images,
            startDate: route.query.start_date,
            endDate: route.query.end_date,
          }
        },
        meta: {
          pageTitle: () => i18n.t('Returns'),
          permissions: ['manage_returns'],
          buttons: [newReturnFormBtn],
          parent: 'returns-overview',
        },
      },
      {
        name: RETURN_PREFILL_FORM,
        path: '/returns/prefilled/:parcelId',
        component: () => import('@/features/returns/pages/PrefilledReturnPage.vue'),
        props: (route: RouteLocationNormalized & { params: { parcelId: string } }) => ({
          parcelId: Number.parseInt(route.params.parcelId),
          direction: route.query.direction || undefined,
        }),
        meta: {
          pageTitle: () => i18n.t('Create new return'),
          permissions: ['manage_returns'],
          parent: 'returns-overview',
        },
      },

      {
        name: RETURN_MANUAL_FORM,
        path: '/returns/new/',
        component: () => import('@/features/returns/pages/NewReturnPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Create new return'),
          permissions: ['manage_returns'],
          parent: 'returns-overview',
        },
      },

      {
        name: RETURNS_EXPORT,
        path: '/returns/export/',
        component: () => import('@/features/returns/pages/ReturnsExportPage.vue'),
        meta: {
          pageTitle: () => i18n.t('Export returns'),
          permissions: ['manage_returns'],
          parent: 'returns-overview',
        },
      },

      {
        name: RETURN_DETAILS_ROUTE,
        path: '/returns/:returnShipmentId',
        component: () => import('@/features/returns/components/ReturnDetails.vue'),
        props: (route: RouteLocationNormalized & { params: { returnShipmentId: string } }) => ({
          returnShipmentId: parseInt(route.params.returnShipmentId),
        }),
        meta: {
          pageTitle: () => i18n.t('Returns'),
          permissions: ['manage_returns'],
          parent: 'returns-overview',
        },
      },
    ],
  },
] satisfies RouteRecordRaw[]
