type DefineEmitsObject = Record<string, unknown[]>
type Cb<DefineEmits extends DefineEmitsObject, T extends keyof DefineEmits> = (...args: DefineEmits[T]) => void
type Options = { once?: boolean }

export type EmitToCallback<Params extends unknown[]> = (...args: Params) => void

export class BaseEventEmitter<
  DefineEmits extends DefineEmitsObject,
  EventName extends keyof DefineEmits = keyof DefineEmits,
> {
  /**
   * Map: { someEvent: Set([cb1, cb2]) }
   */
  private eventListenersMap: Map<EventName, Set<Cb<DefineEmits, EventName>>> = new Map()

  /**
   * If callback is in this map, then it should be called only once
   * Map: { someCallback: true }
   */
  private onceMap: Map<Cb<DefineEmits, EventName>, true> = new Map()

  private updateOnceValue({
    callback,
    once,
    listenerAlreadyExists,
  }: {
    callback: () => void
    once: boolean
    listenerAlreadyExists: boolean
  }) {
    // give priority to non-once callbacks
    if (!once) {
      return this.onceMap.delete(callback)
    }

    // our new once === true, so
    // if listener exists, we do not need to change anything (if its once, its fine, if non-once - we give it priority)
    if (listenerAlreadyExists) {
      return
    }

    // only if it is a new callback add `once: true`
    return this.onceMap.set(callback, true)
  }

  private registerEventListener<T extends EventName>(
    eventName: T,
    callback: Cb<DefineEmits, T>,
    { once = false }: Options = {},
  ) {
    const listenersSet: Set<Cb<DefineEmits, T>> = this.eventListenersMap.get(eventName) || new Set()

    const listenerAlreadyExists = listenersSet.has(callback)

    this.updateOnceValue({ callback, once, listenerAlreadyExists })

    if (listenerAlreadyExists) {
      return
    }
    listenersSet.add(callback)
    this.eventListenersMap.set(eventName, listenersSet as Set<Cb<DefineEmits, EventName>>)
  }

  $on<T extends EventName>(eventName: T, callback: Cb<DefineEmits, T>, options?: Options) {
    this.registerEventListener(eventName, callback, options)
  }

  $once<T extends EventName>(eventName: T, callback: Cb<DefineEmits, T>) {
    this.registerEventListener(eventName, callback, { once: true })
  }

  /**
   * Removes the event listener for the given event name
   */
  $off<T extends EventName>(eventName: T, callback: Cb<DefineEmits, T>) {
    const listenersSet: Set<Cb<DefineEmits, T>> | undefined = this.eventListenersMap.get(eventName)

    if (!listenersSet?.size) {
      return
    }

    return listenersSet.delete(callback)
  }

  /**
   * Removes all event listeners for the given event
   */
  $removeAllListeners<T extends EventName>(eventName: T) {
    this.eventListenersMap.delete(eventName)
  }

  /**
   * See: https://v2.vuejs.org/v2/api/#vm-emit
   */
  $emit<T extends EventName>(eventName: T, ...args: Parameters<Cb<DefineEmits, T>>) {
    const listenersSet = this.eventListenersMap.get(eventName)
    if (!listenersSet?.size) {
      return
    }
    listenersSet.forEach((cb) => {
      cb(...args)
      const isOnce = this.onceMap.get(cb)
      if (isOnce) {
        this.onceMap.delete(cb)
        listenersSet.delete(cb)
      }
    })
  }

  $reset() {
    this.eventListenersMap = new Map()
    this.onceMap = new Map()
  }
}
