import type { ReturnsPortalLayoutType } from '@/features/return-portal/types'

export const DEFAULT_REASON_ID = 3

export const PAYMENT_PROVIDER_STATUS_NEEDS_DATA = 'needs-data'
export const PAYMENT_PROVIDER_STATUS_IN_REVIEW = 'in-review'
export const PAYMENT_PROVIDER_STATUS_COMPLETED = 'completed'

export const RETURNS_PORTAL_FEATURE_ID = 'returns_portal'
export const RETURN_SETTINGS_ONBOARDING = 'returns-settings'

export const RETURN_PORTAL_LAYOUT_TYPES: Record<string, ReturnsPortalLayoutType> = {
  STANDARD: 'standard',
  BACKGROUND_IMAGE: 'background_image',
}

export const DEFAULT_MOLLIE_PAYMENT_SETTINGS = {
  name: 'Mollie',
  authorization_url: '/xhr/payments/mollie/auth',
  onboarding_url: 'https://my.mollie.com/dashboard/onboarding',
  profile_url: 'https://api.mollie.com/v2/onboarding/me',
  type: 'mollie',
}

export const RETURN_PORTAL_LANGUAGES: { id: string, label: string }[] = [
  { id: 'en-US', label: 'English (US)' },
  { id: 'en-GB', label: 'English (UK)' },
  { id: 'es-ES', label: 'Español' },
  { id: 'fr-FR', label: 'Français' },
  { id: 'de-DE', label: 'Deutsch' },
  { id: 'it-IT', label: 'Italiano' },
  { id: 'nl-NL', label: 'Nederlands' },
  { id: 'ca-ES', label: 'Català' },
]
