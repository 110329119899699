import ReturnAddressService from '@/features/addresses/api/return-address.api'

import { RETURNS_SET_ADDRESSES, RETURNS_SET_ADDRESS } from './mutation.types'
import {
  RETURNS_ADDRESS_ACTIVATE_DEFAULT,
  RETURNS_ADDRESS_CREATE,
  RETURNS_ADDRESS_DELETE,
  RETURNS_ADDRESS_FETCH,
  RETURNS_ADDRESS_UPDATE,
  RETURNS_ADDRESSES_FETCH_ALL_LAZILY,
  RETURNS_ADDRESSES_FETCH_ALL,
} from './action.types'
import { SENDER_ADDRESS_FETCH_ADDRESSES } from '@/features/addresses/stores/sender-addresses/action.types'

import type {
  AddressBasePayload,
  ReturnAddress,
} from '@/features/addresses/types/addresses.types'
import type { Commit, Dispatch } from 'vuex'

type ReturnAddressState = {
  address: ReturnAddress | {}
  addresses: ReturnAddress[]
}

const initialState: ReturnAddressState = {
  address: {},
  addresses: [],
}

const getters = {
  returnAddresses: (state: ReturnAddressState) => {
    return state.addresses
  },
}

const actions = {
  async [RETURNS_ADDRESSES_FETCH_ALL]({ commit }: { commit: Commit }) {
    const addresses = await ReturnAddressService.findAll()
    commit(RETURNS_SET_ADDRESSES, addresses)
  },
  async [RETURNS_ADDRESS_ACTIVATE_DEFAULT](
    { state, commit, dispatch }: { state: ReturnAddressState, commit: Commit, dispatch: Dispatch }, addressID: number,
  ) {
    await ReturnAddressService.setActive(addressID)
    const addresses = state.addresses.map((address) => {
      const copy = { ...address, is_active: address.id === addressID }
      return copy
    })
    // NOTE: Apparently, whenever a return address is marked as default, sender addresses are also refetched.
    // This behavior is kept here but the whole address relationship _might_ be revisited
    await dispatch(SENDER_ADDRESS_FETCH_ADDRESSES)
    // (end NOTE)
    commit(RETURNS_SET_ADDRESSES, addresses)
  },
  async [RETURNS_ADDRESS_DELETE](
    { state, commit, dispatch }: { state: ReturnAddressState, commit: Commit, dispatch: Dispatch }, addressID: number,
  ) {
    await ReturnAddressService.delete(addressID)
    // NOTE: Apparently, whenever a return address is removed sender addresses are also refetched as they
    // might contain references to a deleted return address - this behavior is kept here but the whole
    // address relationship _might_ be revisited
    await dispatch(SENDER_ADDRESS_FETCH_ADDRESSES)
    // (end NOTE)

    const addresses = state.addresses.filter(address => address.id !== addressID)
    commit(RETURNS_SET_ADDRESSES, addresses)
  },
  async [RETURNS_ADDRESS_CREATE]({ dispatch }: { dispatch: Dispatch }, addressData: AddressBasePayload) {
    const address = await ReturnAddressService.create(addressData)
    // NOTE: the original implementation was refetching addresses after creation. This behavior is kept here
    await Promise.all([dispatch(RETURNS_ADDRESSES_FETCH_ALL), dispatch(SENDER_ADDRESS_FETCH_ADDRESSES)])
    // (end NOTE)
    return address
  },
  async [RETURNS_ADDRESS_UPDATE]({ dispatch }: { dispatch: Dispatch }, address: ReturnAddress) {
    const updatedAddress = await ReturnAddressService.update(address.id, address)
    // NOTE: the original implementation was refetching addresses after update. This behavior is kept here
    await Promise.all([dispatch(RETURNS_ADDRESSES_FETCH_ALL), dispatch(SENDER_ADDRESS_FETCH_ADDRESSES)])
    // (end NOTE)
    return updatedAddress
  },
  async [RETURNS_ADDRESS_FETCH]({ commit }: { commit: Commit }, id: number) {
    const address = await ReturnAddressService.find(id)
    commit(RETURNS_SET_ADDRESS, address)
  },
  async [RETURNS_ADDRESSES_FETCH_ALL_LAZILY]({ state, dispatch }: { state: ReturnAddressState, dispatch: Dispatch }) {
    if (state.addresses.length === 0) {
      await dispatch(RETURNS_ADDRESSES_FETCH_ALL)
    }
  },
}

const mutations = {
  [RETURNS_SET_ADDRESSES](state: ReturnAddressState, addresses: ReturnAddress[]) {
    state.addresses = addresses
  },

  [RETURNS_SET_ADDRESS](state: ReturnAddressState, address: ReturnAddress) {
    state.address = address
  },
}

export default {
  state: initialState,
  getters,
  actions,
  mutations,
}
