import type { Commit, Dispatch } from 'vuex'
import PickupAddressService from '@/features/addresses/api/pickup-address.api'
import {
  PICKUPS_CREATE_ADDRESS,
  PICKUPS_FETCH_ADDRESSES,
  PICKUPS_DELETE_ADDRESS,
  PICKUPS_UPDATE_ADDRESS,
  PICKUPS_FETCH_ADDRESS,
} from '@/features/addresses/stores/pickup-addresses/action.types'
import { PICKUPS_SET_ADDRESS, PICKUPS_SET_ADDRESSES } from '@/features/addresses/stores/pickup-addresses/mutation.types'
import type { AddressBasePayload, PickupAddress } from '@/features/addresses/types/addresses.types'

type PickupAddressesState = {
  pickupAddresses: PickupAddress[]
  pickupAddress: PickupAddress
}

const state = {
  pickupAddresses: [],
  pickupAddress: {},
}

const getters = {
  pickupAddresses: (state: PickupAddressesState) => state.pickupAddresses,
}

const actions = {
  async [PICKUPS_FETCH_ADDRESSES]({ commit }: { commit: Commit }) {
    const response = await PickupAddressService.findAll()
    commit(PICKUPS_SET_ADDRESSES, response.data)
  },
  async [PICKUPS_FETCH_ADDRESS]({ commit }: { commit: Commit }, id: PickupAddress['id']) {
    const response = await PickupAddressService.find(id)
    commit(PICKUPS_SET_ADDRESS, response.data)
  },
  async [PICKUPS_DELETE_ADDRESS]({ dispatch }: { dispatch: Dispatch }, id: PickupAddress['id']) {
    await PickupAddressService.destroy(id)
    await dispatch(PICKUPS_FETCH_ADDRESSES)
  },
  async [PICKUPS_CREATE_ADDRESS]({ dispatch }: { dispatch: Dispatch }, address: AddressBasePayload) {
    await PickupAddressService.create(address)
    await dispatch(PICKUPS_FETCH_ADDRESSES)
  },
  async [PICKUPS_UPDATE_ADDRESS]({ dispatch }: { dispatch: Dispatch }, address: PickupAddress) {
    await PickupAddressService.update(address)
    await dispatch(PICKUPS_FETCH_ADDRESSES)
  },
}

const mutations = {
  [PICKUPS_SET_ADDRESSES](state: PickupAddressesState, addresses: PickupAddress[]) {
    state.pickupAddresses = addresses
  },
  [PICKUPS_SET_ADDRESS](state: PickupAddressesState, address: PickupAddress) {
    state.pickupAddress = address
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
