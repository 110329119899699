import swal from 'sweetalert'
import type { SwalParams } from 'sweetalert/typings/core'
import WARNING from '@/common/assets/img/icons/warning.svg'
import ERROR from '@/common/assets/img/icons/error.svg'
import SUCCESS from '@/common/assets/img/icons/success.svg'
import INFO from '@/common/assets/img/icons/info.svg'

import i18n from '@/application/i18n/i18n'

// SwalParams allows elements to be either strings or Partial<SwalOptions> objects.
// We only want to accept Partial<SwalOptions> objects, so we exclude strings
type SwalParamsObject = Exclude<SwalParams[number], string>

export const TEXTS = {
  areYouSure: () => i18n.t('Are you sure?'),
  yes: () => i18n.t('Yes'),
  alert: () => i18n.t('Alert'),
  delete: () => i18n.t('Delete'),
  disable: () => i18n.t('Disable'),
  cancel: () => i18n.t('Cancel'),
  ok: () => i18n.t('Ok'),
}

export const TYPE = {
  WARNING,
  ERROR,
  SUCCESS,
  INFO,
  NEUTRAL: '', // no icon shown
}
/**
 * Place alert into dialog if one exists and open
 */
function placeInDialog() {
  const dialog = document.querySelector('dialog.ui-modal')

  if (dialog && dialog.hasAttribute('open')) {
    const alert = document.querySelector('.swal-overlay')
    if (alert) {
      dialog.appendChild(alert)
    }
  }
}

/**
 * For basic alert/confirmation dialogs, use this service so that
 * we can plugin e.g. SweetAlert at some point in time.
 */
export default {
  /**
   * A notification alert (without any buttons)
   * Usage:
   * alert({text: "Succeeded"}, function() {...})
   */
  alert(opts: SwalParamsObject) {
    const alert = swal({
      title: TEXTS.alert(),
      icon: TYPE.NEUTRAL,
      buttons: {
        confirm: {
          className: 'ui-button ui-button--primary',
        },
      },
      ...opts,
    })
    placeInDialog()

    return alert
  },

  /**
   * Use to confirm regular actions.
   * Usage:
   * confirm({text: "sure?"}, function(confirmed) {...})
   */
  confirm(opts: SwalParamsObject) {
    const cancelButton = {
      text: TEXTS.cancel(),
      visible: true,
      className: 'ui-button ui-button--secondary',
    }
    const confirmButton = {
      text: TEXTS.ok(),
      className: 'ui-button ui-button--primary',
    }

    if (opts.buttons && !Array.isArray(opts.buttons)) {
      opts.buttons.cancel = opts.buttons.cancel === true ? cancelButton : opts.buttons.cancel
      opts.buttons.confirm = opts.buttons.confirm === true ? confirmButton : opts.buttons.confirm
    }

    const confirm = swal({
      title: TEXTS.areYouSure(),
      icon: TYPE.WARNING,
      buttons: {
        cancel: cancelButton,
        confirm: confirmButton,
      },
      ...opts,
    })
    placeInDialog()

    return confirm
  },

  /**
     * Use to confirm dangerous/destructive actions, such as delete.

     * Usage:
     * confirmDanger({text: "sure?"}, function(confirmed) {...})
     */
  confirmDanger(opts: SwalParamsObject) {
    const cancelDangerButton = {
      text: TEXTS.cancel(),
      visible: true,
    }
    const confirmDangerButton = {
      text: TEXTS.delete(),
      className: 'ui-button ui-button--danger',
    }

    if (opts.buttons && !Array.isArray(opts.buttons)) {
      opts.buttons.cancel = opts.buttons.cancel === true ? cancelDangerButton : opts.buttons.cancel
      opts.buttons.confirm = opts.buttons.confirm === true ? confirmDangerButton : opts.buttons.confirm
    }

    const confirmDanger = swal({
      title: TEXTS.areYouSure(),
      icon: TYPE.ERROR,
      dangerMode: true,
      buttons: {
        cancel: cancelDangerButton,
        confirm: confirmDangerButton,
      },
      ...opts,
    })
    placeInDialog()

    return confirmDanger
  },

  /**
   * Use to display information in a bare dialog.
   * No buttons, no text, no nothing.
   */
  bare(opts: SwalParamsObject) {
    const bare = swal({
      icon: TYPE.NEUTRAL,
      buttons: [false],
      ...opts,
    })
    placeInDialog()

    return bare
  },

  /**
   * Use to programmatically close the currently open dialog.
   */
  close() {
    if (swal?.getState?.().isOpen) {
      swal.close?.()
    }
  },

  stopLoading() {
    if (swal?.getState?.().isOpen) {
      swal.stopLoading?.()
    }
  },
}
