import axios from '@/common/utils/axios'
import { strictParse } from '@/common/utils/parsing-utils/strict-parse'
import {
  WhatsAppCustomTemplate,
  WhatsAppCustomTemplateRequest,
} from '@/features/tracking-messages/types/tracking-messages-data-parsers'
import type {
  AddCountryParams,
  TrackingMessageWhatsAppSettings,
  WhatsappProviderName,
} from '@/features/tracking-messages/types/tracking-messages.types'

export async function getWhatsAppSettingsList(brandId: number): Promise<TrackingMessageWhatsAppSettings[]> {
  const { data } = await axios.get<{ data: TrackingMessageWhatsAppSettings[] }>(
    `/xhr/notify/whatsapp-settings/${brandId}`,
  )

  return data.data
}

// TODO: Remove this after 'tracking_messages_all_settings_by_brand' betaFeature is rollout
export async function fetchWhatsAppTrackingMessageSettings(brandId: number): Promise<{ send_whatsapp: boolean }> {
  const { data } = await axios.get(`/xhr/notify/${brandId}/settings`)
  return data
}

export async function selectProvider(params: { brandId: number, providerName: WhatsappProviderName }): Promise<void> {
  const { brandId, providerName } = params
  await axios.post(`/xhr/notify/whatsapp-settings/${brandId}/activation/${providerName}`)
}

export async function updateWhatsAppSettings(brandId: number, whatsAppSettings: TrackingMessageWhatsAppSettings) {
  const { data } = await axios.put(`/xhr/notify/whatsapp-settings?brand_id=${brandId}`, whatsAppSettings)
  return data.data
}

export async function getSendcloudWabaMessageTemplate(params: {
  brandId: number
  messageGroup: string
  parcelStatus: string
  countryIso2: string
}): Promise<{ message_content: string }> {
  const { brandId, messageGroup, parcelStatus, countryIso2 } = params
  const { data } = await axios.get(`/xhr/notify/whatsapp-templates`, {
    params: {
      brand_id: brandId,
      message_group: messageGroup,
      status: parcelStatus,
      country: countryIso2,
    },
  })
  return data.data
}

export async function addWhatsAppCountry({ brandId, countryIso2, providerName }: AddCountryParams) {
  await axios.post(`/xhr/notify/whatsapp-templates`, {
    brand_id: brandId,
    country: countryIso2,
    provider: providerName,
  })
}

export async function deleteWhatsAppCountry({
  brandId,
  countryIso2,
  provider,
}: {
  brandId: number
  countryIso2: string
  provider: WhatsappProviderName
}) {
  await axios.delete(`/xhr/notify/whatsapp-templates/${brandId}/${countryIso2}?provider=${provider}`)
}

export async function deleteWhatsAppSettings({
  brandId,
  providerName,
}: {
  brandId: number
  providerName: WhatsappProviderName
}): Promise<void> {
  await axios.delete(`/xhr/notify/whatsapp-settings/${brandId}/${providerName}`)
}

export async function getWhatsAppCustomTemplate(templateId: string): Promise<WhatsAppCustomTemplate> {
  const { data } = await axios.get<{ data: WhatsAppCustomTemplate }>(`/xhr/notify/whatsapp/templates/${templateId}`)

  return strictParse(data.data, WhatsAppCustomTemplate, 'getWhatsAppCustomTemplate')
}

export async function updateWhatsAppCustomTemplate(
  template: WhatsAppCustomTemplateRequest,
): Promise<WhatsAppCustomTemplate> {
  const { data } = await axios.post<{ data: WhatsAppCustomTemplate }>(`/xhr/notify/whatsapp/templates`, template)

  return strictParse(data.data, WhatsAppCustomTemplate, 'updateWhatsAppCustomTemplate')
}

export async function restoreWhatsAppCustomTemplate(templateId: string): Promise<WhatsAppCustomTemplate> {
  const { data } = await axios.post<{ data: WhatsAppCustomTemplate }>(
    `/xhr/notify/whatsapp/templates/${templateId}/restore`,
  )

  return strictParse(data.data, WhatsAppCustomTemplate, 'restoreWhatsAppCustomTemplate')
}
