import axios from '@/common/utils/axios'
import type { AddressBasePayload, PickupAddress } from '@/features/addresses/types/addresses.types'

export default {
  create(address: AddressBasePayload): Promise<{ data: PickupAddress }> {
    return axios.post('/xhr/pickupaddress', address)
  },

  destroy(id: PickupAddress['id']) {
    return axios.delete(`/xhr/pickupaddress/${id}`)
  },

  find(id: PickupAddress['id']) {
    return axios.get(`/xhr/pickupaddress/${id}`)
  },

  findAll(): Promise<{ data: PickupAddress[] }> {
    return axios.get('/xhr/pickupaddress')
  },

  update(address: PickupAddress): Promise<{ data: PickupAddress }> {
    return axios.patch(`/xhr/pickupaddress/${address.id}`, address)
  },
}
