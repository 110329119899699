<template>
  <UiAlert
    v-if="message && !messageWasDismissed"
    :icon="messageKey === 'account-on-hold'"
    class="ui-margin-16--bottom"
    :mode="hasOverdueInvoices ? 'danger' : 'warning'"
    role="alert"
    data-test="message-bar"
  >
    <div class="message-bar">
      <p class="ui-margin-0--bottom">
        <template v-if="showEscalatedMessage && message.messageContentEscalated">
          {{ message.messageContentEscalated }}
        </template>

        <template v-else-if="message.messageContent">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="markedContent(message.messageContent)" />
          <!-- eslint-enable vue/no-v-html -->
        </template>
      </p>

      <div class="message-bar__actions">
        <div class="ui-button-group">
          <router-link
            v-if="typeof message.actionRouteName === 'string'"
            class="ui-button ui-button--secondary"
            :to="{ name: message.actionRouteName }"
            data-test="message-bar-action-button"
          >
            {{ message.actionText }}
          </router-link>

          <button
            v-else-if="typeof message.action === 'function'"
            class="ui-button ui-button--secondary"
            type="button"
            data-test="message-bar-action-button"
            @click="message.action"
          >
            {{ message.actionText }}
          </button>

          <button
            v-if="message.isDismissable"
            class="ui-button ui-button--borderless ui-button--icon-only ui-button--small"
            type="button"
            data-test="message-bar-dismiss-button"
            @click="dismissMessage"
          >
            <span aria-hidden="true">✕</span>

            <span class="sr-only">{{ $t('Close') }}</span>
          </button>
        </div>
      </div>
    </div>
  </UiAlert>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { UiAlert } from '@sendcloud/ui-library'

import { useDateTime } from '@/common/utils/use-date-time'
import { useMarkedContent } from '@/common/services/use-marked-content'

import DialogsService, { TYPE } from '@/common/services/dialogs.service'
import ToastService from '@/common/services/toast.service'

import { CORE_ACCOUNT_UNFREEZE } from '@/common/stores/core/action.types'
import {
  SETTINGS_FINANCIAL_PAYMENTS_ROUTE,
  SETTINGS_FINANCIAL_INVOICES_ROUTE,
} from '@/features/financial/routes'
import { SUPPORT_NEW_TICKET_ROUTE } from '@/features/support/routes'
import type { User } from '@/types/models'

const store = useStore()
const { t } = useI18n()

const { markedContent } = useMarkedContent()
const { getCalendarDate } = useDateTime()

const ADYEN_REASON_CODE = {
  AC01_INCORRECT_ACCOUNT_NUMBER: 'AC01',
  AC04_CLOSED_ACCOUNT: 'AC04',
  AC06_BLOCKED_ACCOUNT: 'AC06',
  AM04_INSUFFICIENT_FUNDS: 'AM04',
  MD06_MULTIPLE_CHARGEBACKS: 'MD06',
  MS02_REFUSED_COLLECTION: 'MS02',
  MS03_UNSPECIFIED_REASON: 'MS03',
  SL01_SPECIFIC_SERVICE: 'SL01',
}

const messageWasDismissed = ref(false)

const user = computed<User>(() => store.getters.user)
const hasOverdueInvoices = computed(() => user.value.has_overdue_invoices)

const messageKey = computed<string>(() => {
  if (!user.value) {
    return ''
  }

  if (user.value.is_account_on_hold) {
    return 'account-on-hold'
  }

  if (user.value.has_overdue_invoices) {
    return 'due-invoices'
  }

  if (user.value.has_failed_payments) {
    return 'failed-payments'
  }

  if (user.value.delete_requested_at && user.value.delete_request_deadline) {
    return 'account-deletion-scheduled'
  }

  if (Array.isArray(user.value.validated_emails) && user.value.validated_emails.length === 0) {
    return 'unverified-email'
  }

  if (user.value.adyen_status.code) {
    return user.value.adyen_status.code
  }

  return ''
})

const message = computed<Message | null>(() => getMessage(messageKey.value))

const showEscalatedMessage = computed<boolean | undefined>(() => {
  return user.value?.adyen_status?.show_escalated_message
})

watch(messageKey, () => {
  messageWasDismissed.value = false
})

function dismissMessage(): void {
  messageWasDismissed.value = true
}

async function unfreezeAccount(): Promise<void> {
  const confirm = await DialogsService.confirm({
    text: t('Your account will be restored and you will be able to create labels.'),
    icon: TYPE.INFO,
  })

  if (confirm) {
    try {
      await store.dispatch(CORE_ACCOUNT_UNFREEZE, user.value)
    } catch {
      ToastService.warning(t('Unable to restore this account.'))
      return
    }
  }

  ToastService.info(t('Your account was successfully restored.'))
}

interface Message {
  action?: () => void
  actionRouteName?: string
  actionText?: string
  isDismissable: boolean
  messageContent: string
  messageContentEscalated?: string
}

function getMessage(messageKey: string): Message | null {
  if (messageKey === '') {
    return null
  }

  if (messageKey === 'account-on-hold') {
    return {
      messageContent: t('account_onhold_warning'),
      isDismissable: false,
    }
  }

  if (messageKey === ADYEN_REASON_CODE.AC01_INCORRECT_ACCOUNT_NUMBER) {
    return {
      messageContent: t(
        `Your account number is incorrect. Please retry the verification payment to continue/start shipping.`,
      ),
      actionText: t('Financial settings'),
      actionRouteName: SETTINGS_FINANCIAL_PAYMENTS_ROUTE,
      isDismissable: false,
    }
  }

  if (messageKey === ADYEN_REASON_CODE.AC04_CLOSED_ACCOUNT) {
    return {
      messageContent: t(
        `Your bank account is blocked or closed. Please check your financial settings to continue shipping.`,
      ),
      actionText: t('Financial settings'),
      actionRouteName: SETTINGS_FINANCIAL_PAYMENTS_ROUTE,
      isDismissable: false,
    }
  }

  if (messageKey === ADYEN_REASON_CODE.AC06_BLOCKED_ACCOUNT) {
    return {
      messageContent: t(
        `Your bank account is blocked or closed. Please check your financial settings to continue shipping.`,
      ),
      actionText: t('Financial settings'),
      actionRouteName: SETTINGS_FINANCIAL_PAYMENTS_ROUTE,
      isDismissable: false,
    }
  }

  if (messageKey === ADYEN_REASON_CODE.AM04_INSUFFICIENT_FUNDS) {
    return {
      messageContent: t(
        `Payment of your invoice has failed due to insufficient funds. We will try again within 5 days.`,
      ),
      messageContentEscalated: t(
        `Payment of your invoice has failed three times due to insufficient funds. Please review your invoice to continue shipping.`,
      ),
      actionText: showEscalatedMessage.value ? t('Review invoices') : undefined,
      actionRouteName: showEscalatedMessage.value ? SETTINGS_FINANCIAL_INVOICES_ROUTE : undefined,
      isDismissable: !showEscalatedMessage.value,
    }
  }

  if (messageKey === ADYEN_REASON_CODE.MD06_MULTIPLE_CHARGEBACKS) {
    return {
      messageContent: t(
        `We noted a refund request. If there are any payment problems, please reach out to us.`,
      ),
      actionText: t('Customer service'),
      actionRouteName: SUPPORT_NEW_TICKET_ROUTE,
      isDismissable: false,
    }
  }

  if (messageKey === ADYEN_REASON_CODE.MS02_REFUSED_COLLECTION) {
    return {
      messageContent: t(
        `Something went wrong in the payment process. Please review your invoices to continue shipping.`,
      ),
      actionText: t('Review invoices'),
      actionRouteName: SETTINGS_FINANCIAL_INVOICES_ROUTE,
      isDismissable: false,
    }
  }

  if (messageKey === ADYEN_REASON_CODE.MS03_UNSPECIFIED_REASON) {
    return {
      messageContent: t(`Something went wrong in the payment process. We will try again within 5 days.`),
      isDismissable: true,
    }
  }

  if (messageKey === ADYEN_REASON_CODE.SL01_SPECIFIC_SERVICE) {
    return {
      messageContent: t(
        `Something went wrong in the payment process. Please check you financial settings to continue shipping.`,
      ),
      actionText: t('Financial settings'),
      actionRouteName: SETTINGS_FINANCIAL_PAYMENTS_ROUTE,
      isDismissable: false,
    }
  }

  if (messageKey === 'due-invoices') {
    return {
      messageContent: t(
        `You have one or more open invoices. To continue shipping, please review your invoices.`,
      ),
      actionText: t('Review invoices'),
      actionRouteName: SETTINGS_FINANCIAL_INVOICES_ROUTE,
      isDismissable: false,
    }
  }

  if (messageKey === 'failed-payments') {
    return {
      messageContent: t(`Payment failed for one or more of your invoices, please review your invoices.`),
      actionText: t('Review invoices'),
      actionRouteName: SETTINGS_FINANCIAL_INVOICES_ROUTE,
      isDismissable: false,
    }
  }

  if (messageKey === 'account-deletion-scheduled') {
    // We ensure above that this messageKey is only assigned if user.value.delete_request_deadline is not null
    const deadline = getCalendarDate(user.value.delete_request_deadline as number)

    return {
      messageContent: t(
        `Your account is scheduled for deletion. If you want to undo this you have until {deadline} to restore your account.`,
        { deadline },
      ),
      actionText: t('Restore account'),
      action: () => {
        unfreezeAccount()
      },
      isDismissable: false,
    }
  }

  return null
}
</script>

<style lang="scss" scoped>
.message-bar {
  display: flex;

  @include breakpoint-max-width(600px) {
    flex-direction: column;
  }

  @include breakpoint-min-width(600px) {
    align-items: center;
    justify-content: space-between;
  }
}

.message-bar__actions {
  flex-shrink: 0;

  @include breakpoint-max-width(600px) {
    margin-top: $sc-spacing-8;
  }

  @include breakpoint-min-width(600px) {
    margin-left: 10px;
  }
}

.ui-button-group {
  align-items: center;
  display: inline-flex;
}

.ui-button-group > :not(:first-child) {
  margin-left: $sc-spacing-8;
}
</style>
