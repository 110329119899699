import { array, boolean, discriminatedUnion, enumValue, literal, number, object, optional, string, type ParserResult } from '@/common/data-parsers'
import { CUSTOM_TEMPLATES_BUTTON_TYPES, CUSTOM_TEMPLATES_COMPONENT_TYPES, POST_PURCHASE_DELAY_VALUES, WHATSAPP_PROVIDER, WHATSAPP_TEMPLATE_REJECTED_REASONS, WHATSAPP_TEMPLATE_STATUSES } from '@/features/tracking-messages/constants'

// TODO: Remove this after 'tracking_messages_all_settings_by_brand' betaFeature is rollout
export const TrackingMessagesSettings = object({
  send_emails: boolean(),
  send_sms: boolean(),
}, 'TrackingMessagesSettings')

export type TrackingMessagesSettings = ParserResult<typeof TrackingMessagesSettings>

export const TrackingMessagesSettingsPerBrand = object({
  send_emails: boolean(),
  send_sms: boolean(),
  send_whatsapp: boolean(),
}, 'TrackingMessagesSettingsPerBrand')

export type TrackingMessagesSettingsPerBrand = ParserResult<typeof TrackingMessagesSettingsPerBrand>

const CustomTemplateUrlButton = object({
  type: literal(CUSTOM_TEMPLATES_BUTTON_TYPES.URL),
  url: string(),
  text: string(),
}, 'CustomTemplateUrlButton')

export type CustomTemplateUrlButton = ParserResult<typeof CustomTemplateUrlButton>

const CustomTemplatePhoneButton = object({
  type: literal(CUSTOM_TEMPLATES_BUTTON_TYPES.PHONE_NUMBER),
  phone_number: string(),
  text: string(),
}, 'CustomTemplatePhoneButton')

export type CustomTemplatePhoneButton = ParserResult<typeof CustomTemplatePhoneButton>

const CustomTemplateQuickReplyButton = object({
  type: literal(CUSTOM_TEMPLATES_BUTTON_TYPES.QUICK_REPLY),
  text: string(),
}, 'CustomTemplateQuickReplyButton')

export type CustomTemplateQuickReplyButton = ParserResult<typeof CustomTemplateQuickReplyButton>

const CustomTemplateButton = discriminatedUnion('type', [CustomTemplateUrlButton, CustomTemplatePhoneButton, CustomTemplateQuickReplyButton])

export type CustomTemplateButton = ParserResult<typeof CustomTemplateButton>

const CustomTemplateComponentBody = object({
  type: literal(CUSTOM_TEMPLATES_COMPONENT_TYPES.BODY),
  text: string(),
}, 'CustomTemplateComponentBody')

export type CustomTemplateComponentBody = ParserResult<typeof CustomTemplateComponentBody>

const CustomTemplateComponentFooter = object({
  type: literal(CUSTOM_TEMPLATES_COMPONENT_TYPES.FOOTER),
  text: string(),
}, 'CustomTemplateComponentFooter')

export type CustomTemplateComponentFooter = ParserResult<typeof CustomTemplateComponentFooter>

const CustomTemplateComponentButtons = object({
  type: literal(CUSTOM_TEMPLATES_COMPONENT_TYPES.BUTTONS),
  buttons: array(CustomTemplateButton),
}, 'CustomTemplateComponentButtons')

export type CustomTemplateComponentButtons = ParserResult<typeof CustomTemplateComponentButtons>

export const WhatsAppCustomTemplate = object({
  id: string(),
  trigger_event: string(),
  is_default: boolean(),
  active: boolean(),
  country: string(),
  brand_id: number(),
  provider: enumValue(WHATSAPP_PROVIDER),
  status: enumValue(WHATSAPP_TEMPLATE_STATUSES),
  status_updated_at: optional(string()),
  rejection_reason: optional(enumValue(WHATSAPP_TEMPLATE_REJECTED_REASONS)),
  components: array(
    CustomTemplateComponentFooter, CustomTemplateComponentBody, CustomTemplateComponentButtons,
  ),
  delay: enumValue(POST_PURCHASE_DELAY_VALUES),
}, 'WhatsAppCustomTemplate')

export type WhatsAppCustomTemplate = ParserResult<typeof WhatsAppCustomTemplate>

export const WhatsAppCustomTemplateRequest = object({
  brand_id: number(),
  country: string(),
  provider: enumValue(WHATSAPP_PROVIDER),
  trigger_event: string(),
  components: array(
    CustomTemplateComponentFooter, CustomTemplateComponentBody, CustomTemplateComponentButtons,
  ),
  delay: enumValue(POST_PURCHASE_DELAY_VALUES),
})

export type WhatsAppCustomTemplateRequest = ParserResult<typeof WhatsAppCustomTemplateRequest>
