import type { InvoiceAddress } from '@/features/addresses/types/addresses.types'

import axios from '@/common/utils/axios'

export default {
  findAll(): Promise<{ data: InvoiceAddress[] }> {
    return axios.get('/xhr/invoiceaddress')
  },

  update(invoiceAddress: InvoiceAddress): Promise<{ data: InvoiceAddress }> {
    return axios.patch(`/xhr/invoiceaddress/${invoiceAddress.id}`, invoiceAddress)
  },
}
